.threeGrid{
    display: grid;
    grid-template-columns: auto auto auto;
    
}


@media screen and (max-width:820px) {

    .threeGrid{
        display: block;
      
        
    }
    
      
}



@media screen and (max-width:650px) {
    
}



@media screen and (max-width:450px) {
    
}
