#studentform{
    width:90%;
    margin-left: auto;
    margin-right: auto;
}

.formbox{
    padding: 15px;
    border-radius: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}


/* Profile Container */
.profile-container {
    width: 93%;
    
    margin: 0 auto;
    padding: 0px;
    background-color: rgba(255, 255, 255, 0.575);
  
    border-radius: 12px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  }
  
  /* Profile Header */
  .profile-header {
    text-align: center;
    padding: 20px 0;
  }
  
  .profile-picture {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    object-fit: cover;
   
    
  }
  
  .profile-name {
    margin-top: 10px;
    font-size: 24px;
    color: #333;
  }
  
