.backgroundblack
{
    width: 100%;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    background-color: white;
    color: black;
   
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
}

.progress-bar
{
    border-radius: 12px;
    color:'red';
    
    background-color: #ff5722;
}