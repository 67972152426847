.image-developer
{
    width: 20%;
  
    margin-left: auto;
    margin-right: auto;
}


@media screen and (max-width:800px) {

    .image-developer
{
    width: 40%;
  
    margin-left: auto;
    margin-right: auto;
}
    
}



@media screen and (max-width:500px) {

    .image-developer
{
    width: 50%;
  
    margin-left: auto;
    margin-right: auto;
}
    
}