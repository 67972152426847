
  
  .table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table td,
  .table th {
    padding: 12px 15px;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 16px;
  }
  
  .table th {
    background-color: #172736;
    color: #ffffff;
  }
  
 
  
  /*responsive*/
  
  @media (max-width: 500px) {
    .table thead {
      display: none;
    }
  
    .table,
    .table tbody,
    .table tr,
    .table td {
      display: block;
      width: 100%;
    }
    .table tr {
      margin-bottom: 15px;
    }
    .table td {
      padding-left: 50%;
      text-align: left;
      position: relative;
    }
    .table td::before {
      content: attr(data-label);
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-size: 15px;
      font-weight: bold;
      text-align: left;
    }
  }
  