/* Typing Text */
#typing
{
    color: rgb(18, 69, 163);
    margin-top: 5%;
    font-size: 20px;
}

h2{
    font-size: 60px;
}

h3{
    font-size: 35px;
}

.scorestats {
    display: grid;
    grid-template-columns: auto auto auto auto;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 25px;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
  #divcounter {
    margin: 3%;
  }
  



/* Offer Section in About Us */

.offersection{
display: grid;
grid-template-columns: auto auto;
margin-top: 5%;
box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px;
width: 90%;
padding: 10px;
border-radius: 12px;
margin-left: auto;
margin-right: auto;

}

.subtext{
    font-size: 23px;
    text-align: left;
    color:'#909090'
}


.paragraph-circle {
    text-align: justify;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    float: left;
   
    margin-top: 5%;
  }

 #title{
     text-align: justify;
 }
  

@media screen and (max-width:930px) {

    h2{
        font-size: 50px;
    }
      
    h3{
        font-size: 32px;
    }
    .paragraph-circle {
        text-align: justify;
        width: 95%;
        margin-left: auto;
        margin-right: auto;
        float: none;
      }

      #title{
        text-align: center;
    }

    #typing
    {
        color: rgb(18, 69, 163);
        margin-top: 10%;
        font-size: 16px;
    }

    /***** Stats Table ******/
.scorestats {
    display: block;
    
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 25px;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
      rgba(0, 0, 0, 0.22) 0px 10px 10px;
  }
    
}



@media screen and (max-width:600px) {
    #typing
{
    color: rgb(18, 69, 163);
    margin-top: 8%;
    font-size: 16px;
}

h2{
    font-size: 40px;
}

h3{
    font-size: 25px;
}




}



@media screen and (max-width:430px) {

    #typing
{
    color: rgb(18, 69, 163);
    margin-top: 9%;
    font-size: 12px;
}

h2{
    font-size: 30px;
}
    
}


@media screen and (max-width:300px) {

    #typing
{
    color: rgb(18, 69, 163);
    margin-top: 14%;
    font-size: 12px;
}

h2{
    font-size: 25px;
}
    
}
