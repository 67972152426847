.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

.bgiimage
{

    width: 95%;
}  

  .container {
    position: relative;
    text-align: center;
    color: #a52020;
  
  }


  h1{
      font-size: 65px;
  }


  @media screen and (max-width:800px) {
      
  h1{
    font-size: 45px;
}

  }



  @media screen and (max-width:600px) {
      
  h1{
    font-size: 25px;
}

}



@media screen and (max-width:400px) {
      
  h1{
    font-size: 20px;
}

}