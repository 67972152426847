/* TestDescription.css */

.test-description {
    
    background-color: #fafafa93;
    border:  none;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
    overflow: auto;
  }
  
  .title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .gridtest {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 10px;
    text-align: left;
  }
  
/* Common styles for labels and values */
.label {
  font-weight: bold;
  color: #007bff; /* Blue color */
}

.value {
  color: #555; /* Dark gray color */
}

/* MarksInput.css */

.marks-container {
  max-width: 95%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  overflow-x: auto; /* Enable horizontal scrolling if needed */
}

.marks-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.marks-table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

th {
  background-color: #007bff;
  color: #fff;
}

.marks-row:hover {
  background-color: #f0f0f0;
}

.marks-input {
  width: 80px;
  padding: 6px;
  text-align: center;
  border: none;
  border-radius: 4px;
  background-color: #f0f0f0;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .marks-container {
    padding: 10px;
  }

  .marks-title {
    font-size: 20px;
  }

  .marks-input {
    width: 60px;
  }

  /* Hide some table columns on smaller screens */
  th:nth-child(2),
  td:nth-child(2),
  th:nth-child(3),
  td:nth-child(3) {
    display: block;
  }
}

/* You can customize the styles further based on your preferences */
